import { Box, Tab, Tabs, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import { ErrorRenderer } from '@hs-baumappe/redkit';
import useOvertimeSignDetails from '../../useOvertimeSignDetails';
import useOvertimeSign from '../../useOvertimeSign';
import { SignatureType } from '../../../../globalTypes';
import ErrorState from '../../../../components/ErrorState';
import SignatureForm from '../../../../components/SignatureForm';

interface OvertimeSignBodyProps {
  overtimeId: string;
  onFormDirty: () => void;
}

export default function OvertimeSignBody({
  overtimeId,
  onFormDirty,
}: OvertimeSignBodyProps): JSX.Element {
  const { t } = useTranslation();

  const { sign, loading: signing, error: signError } = useOvertimeSign(overtimeId);
  const {
    employeeSignature,
    customerSignature,
    employeeNames,
    viewerFullName,
    viewerSignatureBase64,
    loading,
    error,
  } = useOvertimeSignDetails(overtimeId);

  if (loading) {
    return <LayoutLoading />;
  }

  if (error) {
    return <ErrorRenderer apolloError={error} />;
  }

  return (
    <>
      {signError && <ErrorState error={signError} />}

      <Tabs
        defaultSelectedTabId={
          !!employeeSignature ? SignatureType.Customer : SignatureType.HeinrichSchmid
        }
      >
        <Tab
          id={SignatureType.HeinrichSchmid}
          label={t('signOvertimeModal.tab.employee.title')}
          panel={
            <Box stylex={{ marginTop: 'small-x' }}>
              <SignatureForm
                initialValues={{
                  name: employeeSignature?.fullName || viewerFullName || '',
                  signature: employeeSignature?.imagePath ?? '',
                }}
                signatureInputLabel={t('signatureForm.signature.label')}
                lastSignature={viewerSignatureBase64}
                readOnly={!!employeeSignature}
                resignable={!!employeeSignature && !customerSignature}
                loading={signing}
                onSubmit={(values) =>
                  sign({
                    type: SignatureType.HeinrichSchmid,
                    values,
                  })
                }
                onDirtyStateChange={onFormDirty}
              />
            </Box>
          }
        />

        <Tab
          id={SignatureType.Customer}
          label={t('signOvertimeModal.tab.customer.title')}
          disabled={!employeeSignature}
          panel={
            <Box stylex={{ marginTop: 'small-x' }}>
              <SignatureForm
                initialValues={{
                  name: employeeNames.length === 1 ? employeeNames[0] : '',
                }}
                signatureInputLabel={t('signatureForm.signature.label')}
                leftSection={
                  <Text component="div" variant="body-small">
                    {t('signOvertimeModal.tab.customer.description')}
                  </Text>
                }
                names={employeeNames}
                loading={signing}
                onSubmit={(values) =>
                  sign({
                    type: SignatureType.Customer,
                    values,
                  })
                }
                onDirtyStateChange={onFormDirty}
              />
            </Box>
          }
        />
      </Tabs>
    </>
  );
}

import { Box, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormikFormField } from '@hs-baumappe/forms';
import { OvertimeFormValues } from '../../form';
import { formatDateToDateString } from '../../../../utils/date';

interface ProjectInformationSectionProps {
  nextSuggestedNumber?: number;
  disableProjectHsIdField?: boolean;
}

export default function ProjectInformationSection({
  nextSuggestedNumber,
  disableProjectHsIdField,
}: ProjectInformationSectionProps): JSX.Element {
  const { t } = useTranslation();
  const [noField, , noFieldHelpers] = useField<OvertimeFormValues['no']>('no');
  const [projectHsIdField] = useField<OvertimeFormValues['projectHsId']>('projectHsId');
  const [orderDateField] = useField<OvertimeFormValues['orderDate']>('orderDate');

  return (
    <Box>
      <Text variant="title-small" color="muted">
        {t('overtimeForm.information.title')}
      </Text>

      <Box className="row">
        <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            name="no"
            label={t('overtimeForm.information.reportNo')}
            placeholder={
              typeof nextSuggestedNumber === 'number' ? nextSuggestedNumber.toString() : undefined
            }
            endSection={
              noField.value ? (
                <IconButton
                  type="button"
                  icon={<Icon name="undo" />}
                  onClick={() => noFieldHelpers.setValue('')}
                />
              ) : undefined
            }
          />
        </Box>
        <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            type="date"
            name="date"
            label={t('overtimeForm.information.date.label')}
            placeholder={t('overtimeForm.information.date.placeholder')}
            max={formatDateToDateString(new Date())}
          />
        </Box>
      </Box>

      <Box className="row">
        <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            name="projectHsId"
            label={t('overtimeForm.information.projectHsId.label')}
            placeholder={t('overtimeForm.information.projectHsId.placeholder')}
            contentEditable={!disableProjectHsIdField}
            disabled={disableProjectHsIdField}
            value={projectHsIdField.value || '-'}
          />
        </Box>
        <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            name="projectClient"
            label={t('overtimeForm.information.projectClient.label')}
            placeholder={t('overtimeForm.information.projectClient.placeholder')}
          />
        </Box>
      </Box>

      <Box className="row">
        <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            type="date"
            name="orderDate"
            label={t('overtimeForm.information.orderDate.label')}
            placeholder={t('overtimeForm.information.orderDate.placeholder')}
            max={formatDateToDateString(new Date())}
          />
        </Box>
        <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            type="date"
            name="endDate"
            label={t('overtimeForm.information.endDate.label')}
            placeholder={t('overtimeForm.information.endDate.placeholder')}
            min={orderDateField.value}
          />
        </Box>
      </Box>
    </Box>
  );
}
